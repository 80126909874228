<template>
  <vx-card title="Payment">
    <div class="vx-row mb-12">
      <div
        v-bind:class="[
          detail ? detailShow + 'md:w-1/4 w-full mb-base' : '',
          detailHide,
        ]"
      >
        <vs-tabs :color="colorx">
          <vs-tab @click="colorx = 'danger'" label="Open">
            <div class="con-tab-ejemplo">
              <open />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Approved">
            <div class="con-tab-ejemplo">
              <approve />
            </div>
          </vs-tab>
          <vs-tab @click="colorx = 'success'" label="Rejected">
            <div class="con-tab-ejemplo">
              <rejected />
            </div>
          </vs-tab>
          
        </vs-tabs>
      </div>
      <transition name="detail-fade">
        <div
          v-if="detail"
          v-bind:class="[
            detail ? detailShow + 'md:w-3/4 w-full mb-base' : '',
            detailHide,
          ]"
        >
          <vs-row>
            <vs-col
              style="padding-bottom: 8px"
              vs-offset="8"
              vs-type="flex"
              vs-justify="rigth"
              vs-align="rigth"
              vs-w="4"
            >
              <vs-button
                class="ml-auto"
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
                >Close</vs-button
              >
            </vs-col>
            <vs-col
              class="vertical-divider"
              vs-offset="0"
              vs-type="flex"
              vs-justify="center"
              vs-align="center"
              vs-w="1"
            >
              <vs-button
                size="small"
                v-on:click="handleClose"
                color="grey"
                icon-pack="feather"
                icon="icon-x-square"
              ></vs-button>
            </vs-col>
            <!-- form component -->
            
          </vs-row>
        </div>
      </transition>
    </div>
  </vx-card>
</template>
<script>
import open from "./open/open.vue";
import approve from "./approve/approve.vue";
import rejected from "./rejected/rejected.vue";

export default {
  components: {
    open,
    approve,
    rejected,
    
  },
  data() {
    return {
      detailShow: "core vx-col ",
      detailHide: "core vx-col md:w-1/1 w-full mb-base ",
      detail: false,
      dn_id: "",
      dn_code: "",
      cn_number: "",
      cn_date: "",
      tr_number: "",
      tr_date: "",
      componentKey: 0,
    };
  },
  methods: {
    handleClose() {
      this.detail = false;
      this.componentKey += 1;
    },
    handleOpenForm() {
      this.detail = true;
      this.status_detail = "form-create";
    },
  },
};
</script>
<style>
.core-enter-active {
  transition: all 0.3s ease;
}

.core-leave-active {
  transition: all 0.8s cubic-bezier(1, 0.5, 0.8, 1);
}

.core-enter,
    .core-leave-to

    /* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}

.core {
  -webkit-transition: all 1s ease;
  -moz-transition: all 1s ease;
  -o-transition: all 1s ease;
  transition: all 1s ease;
}

.vs-con-table .vs-con-tbody .vs-table--tbody-table {
  font-size: 12px;
}

.vertical-divider {
  border-left: 1px solid #7367f0;
  min-height: 800px;
  padding: 5px;
}

.highlight > td {
  background-color: #d0cdf0;
}

.con-vs-checkbox {
  margin-left: auto;
  margin-right: auto;
}
</style>